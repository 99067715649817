function revealPrices(btn: HTMLButtonElement) {
  const prices = document.querySelectorAll<HTMLElement>('[data-reveal-price]');
  const isHidden = () => btn.classList.contains('is-hidden');
  const show = () => {
    btn.classList.remove('is-hidden');
    prices.forEach(price => {
      price.textContent = price.dataset.revealPrice;
    });
  };
  const hide = () => {
    btn.classList.add('is-hidden');
    prices.forEach(price => {
      price.textContent = '***';
    });
  };
  const toggle = () => {
    if (isHidden()) show();
    else hide();
  };

  btn.addEventListener('click', toggle);
  hide();
}

export function initOrderList() {
  const root = document.querySelector('.order-list');
  if (!root) return;

  const revealPricesBtn = document.querySelector<HTMLButtonElement>('.order-list__reveal-prices');
  if (revealPricesBtn) {
    revealPrices(revealPricesBtn);
  }
}
