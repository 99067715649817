import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';
import { ym } from '../../lib/ym';
import { timeout } from '../../lib/timeout';

function init(swiperElement) {
  const swiper = new Swiper(swiperElement, {
    modules: [Navigation, Pagination, EffectFade, Autoplay],
    init: false,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 1000,
    autoHeight: true,
    autoplay: {
      delay: 7000,
      pauseOnMouseEnter: true,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      init: swiper => {
        const slide = swiper.slides[0];
        if (slide && slide.dataset.swiperSlideTheme) {
          swiperElement.dataset.swiperTheme = slide.dataset.swiperSlideTheme;
        }
      },
      slideChange: swiper => {
        const slide = swiper.slides[swiper.realIndex];
        if (slide && slide.dataset.swiperSlideTheme) {
          swiperElement.dataset.swiperTheme = slide.dataset.swiperSlideTheme;
        }
      },
    },
  });
  swiper.init();

  document.querySelectorAll<HTMLLinkElement>('.main-banner__banner').forEach(element => {
    element.addEventListener('click', async e => {
      if (!element.href) return;

      e.preventDefault();
      ym.reachGoal('topbanner');
      await timeout(300);
      window.location.href = element.href;
    });
  });

  const nyBanner = document.querySelector<HTMLElement>(
    '.main-banner__slide--is-ny2024 .main-banner__content'
  );
  if (nyBanner) {
    import('magic-snowflakes').then(({default: Snowflakes}) => {
      new Snowflakes({
        color: '#ffffff', // Default: "#5ECDEF"
        container: nyBanner, // Default: document.body
        count: 100, // 100 snowflakes. Default: 50
        minOpacity: 0.1, // From 0 to 1. Default: 0.6
        maxOpacity: 0.95, // From 0 to 1. Default: 1
        minSize: 10, // Default: 10
        maxSize: 15, // Default: 25
        rotation: true, // Default: true
        speed: 0.5, // The property affects the speed of falling. Default: 1
        wind: false, // Without wind. Default: true
        // width: 500, // Default: width of container
        // height: 250, // Default: height of container
        zIndex: 100, // Default: 9999,
        autoResize: true, // Default: true
      });
    });
  }
}

export function mainBanner() {
  document.querySelectorAll('.main-banner__swiper').forEach(element => init(element));
}
